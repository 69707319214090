.ant-drawer-close {
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 22px;
    /* margin-right: 12px; */
    color: rgba(0, 0, 0, 0.45);
    font-weight: 1000;
    font-size: 22px;
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
    text-rendering: auto;
}

.ant-drawer-close {
    display: block;
}

.ant-drawer-header {
    border: none;
}

.ant-drawer-content-wrapper .refine-search {
    margin: 15px 0;
    font-size: 16px;
    font-weight: 450;
}

.ant-drawer-content-wrapper .refine-search img {
    margin-top: -2px;
}

@media only screen and (max-width: 600px) {
    #pro {
        position: absolute;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        top: 0.5rem;
        left: 6.5rem;
    }

    .sm-app-header .logo-img {
        width: 60% !important;
        margin-left: -10px;
    }

    .ant-menu-dark #pro .ant-menu-item-selected>span> {
        color: red;
        border-bottom: 2.5px solid red;
    }
}