/* Authentication Module Alignment  Start*/


@media only screen and (max-width: 600px) {
    body {
        width: auto;
        overflow-x: hidden !important;
    }

    .auth-header {
        padding: 20px 10px;
    }

    .page-login,
    .page-register {
        padding: 40px 10px 40px;
    }

    .sm-slider h4,
    .sm-slider p,
    .register-member-info {
        text-align: center !important;
        padding-right: 0px;
    }

    .sm-slider .ant-steps-item-description {
        margin-top: 5px;
    }


    .auth-header p {
        text-align: center !important;
    }

    .sm-auth .ant-form {
        padding: 0px 20px;
    }

    .sm-auth .member-form {
        padding: 0 10px !important;
    }

    .photos-head {
        padding: 10px 0 20px 10px !important;
    }

    .sm-auth .photos-form {
        padding: 0px !important;
    }

    .sm-btn.sm-btn-black {
        width: 100%;
    }

    #root,

    .sm-web,
    .sm-app {
        height: 115vh !important;
        overflow-x: hidden !important;
    }

    .sm-auth {
        height: 115vh !important;
    }

    .ant-carousel .slick-dots {
        justify-content: center;
        margin-right: 0;
        margin-left: 0;
    }

    .sm-card {
        margin: 20px 0px !important;
    }

    .logo-card {
        padding: 0 !important;
    }

    .logo-card .btn>.txt-right>.sm-btn {
        margin-top: 2px !important;
    }

    .page-login,
    .page-register {
        height: calc(80% - 120px);
    }

    .login-footer h1 {
        display: none;
    }

    .ant-carousel .slick-dots-bottom {
        bottom: 0px;
    }

    .ant-steps-vertical {
        display: flex;
        align-content: space-around;
        justify-content: space-around;
        flex-direction: column;
        padding-left: 100px;
    }

    .next-btn-red {
        margin-top: 1.7rem !important;
    }

}


/* Authentication Module Alignment End*/





/* Home page  Start*/


@media only screen and (max-width: 600px) {


    .meet-groom,
    .home-filter,
    .about-blog .blog,
    .sm-page .success-stories .stories,
    .our-branches,
    .sm-page .about-paragraph,
    .web-profile,
    .sm-search,
    .contact-page {
        padding: 40px 20px !important;
    }

    .sm-page .success-stories .stories {
        text-align: center;
        padding: 20px !important;
    }

    .member-profile {
        text-align: left !important;
        padding: 30px 15px !important;
    }

    .member-profile .not-found {
        width: 50% !important;
    }

    .member-profile .no-data {
        text-align: left !important;

    }

    .web-search-btn {
        margin-top: 2.6rem;
    }

    /* .web-profile .profiles {
        padding: 30px 40px;
    } */

    .web-slider {
        height: 39rem;
        padding: 0px 10px 1rem !important;
    }

    .web-slider .banner-img {
        width: 100%;
        background-size: contain;
    }

    .web-slider h2 span {
        font-family: "BickleyScriptRegular";
        font-size: 4.3rem;
    }

    .web-slider .heart-img {
        width: 6rem;
        margin-top: 14px;
        margin-left: 0px;
    }

    .web-slider .btns button {
        font-size: 0.8rem;
        font-weight: 450;
        padding: 6px 8px;
        border: none;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }


    .web-slider .secure-btn {
        position: absolute;
        top: 48%;
        left: 5px;
    }

    .web-slider .support-btn {
        position: absolute;
        top: 58%;
        right: 15px;
    }

    .web-slider .profiles-btn {
        position: absolute;
        top: 75%;
        left: 15px;
    }

    .web-slider .alerts-btn {
        position: absolute;
        top: 83%;
        right: 8%;
    }

    .web-slider .secure-btn img,
    .web-slider .support-btn img,
    .web-slider .profiles-btn img,
    .web-slider .alerts-btn img {
        width: 1.4rem;
        margin-right: 5px;
    }

    .web-slider h2 {
        padding: 10px;
        font-size: 16px;
        font-weight: 500;
        width: 100%;
        line-height: 40px;
        word-wrap: break-word;
    }

    .mob-head {
        height: 5.4rem !important;
    }

    .meet-groom .ant-row {
        display: flex;
        flex-flow: row !important;
        justify-content: flex-start;
        overflow-x: auto;
        gap: 1rem;
    }

    .our-branches .ant-row {
        display: flex;
        flex-flow: row !important;
        justify-content: flex-start;
        overflow-x: auto;
        gap: 2rem;
    }

    .our-branches .ant-row iframe {
        width: 15rem;
        height: 15rem;
    }

    .meet-groom {
        width: 100%;
        position: relative;
    }

    .meet-groom h2 {
        width: 10rem;
    }

    .meet-groom img {
        width: 10rem;
        height: 10rem;
    }

    .meet-groom .mobile-no-data-found {
        display: block;
        margin: 4rem 0;
    }

    .meet-groom .not-found {
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10rem;
        height: 10rem;
    }


    .our-branches img {
        width: 10rem;
        height: 10rem;
    }

    .our-branches p {
        margin-top: -5px;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5rem;
        word-wrap: break-word;
    }

    .arg-img {
        display: none;
    }

    .sm-app-header h1 {
        margin-left: 2rem;
        padding-top: 10px;
        font-size: 1rem;
    }

    .sm-app-header .sm-logo {
        padding: 20px 10px;
        width: 3.3rem !important;
    }

    .sm-app-header .mob-login-btn {
        margin: 1.5rem 0 0 0;
        padding: 0.3rem 0.8rem !important;
        border-radius: 1rem;
        font-size: 11px;
        color: #D91214;
        font-weight: 450;
    }

    .sm-app-header .mob-login-btn img {
        width: 10px;
        margin: 0.1rem 0.4rem 0.2rem;
    }

    .ant-carousel .success-slider .slick-dots {
        position: absolute;
        left: 0%;
    }

    .ant-carousel .success-slider img {
        width: 100%;
        height: 100%;
    }

    .ant-carousel .success-slider .carousel p {
        font-size: 12.5px;
        height: 3.5rem;
        overflow-y: scroll;
        color: #000;
    }

    .ant-carousel .success-slider .carousel .sm-btn {
        font-size: 12px;
        background-color: transparent;
        border-radius: 5px;
        color: #D91214;
        border: 1px solid#D91214;
        font-style: normal;
        padding: 0.4em 1.7em;
        font-weight: 500;
        margin-top: 0px;
    }

    .ant-carousel .success-slider .carousel .sm-btn:hover {
        margin-top: 0px;
    }

    .about-blog p {
        text-align: justify;
    }

    .sm-web-footer {
        padding: 40px 20px;
    }

    .sm-web-footer ul>li:first-child button {
        padding-top: 15px;
    }

    .sm-web-footer .foot-logo {
        max-width: 14rem;
    }

    .sm-web-footer h5,
    .sm-web-footer .foot-logo,
    .social-images {
        margin-left: -1rem;
    }

    .role-page .arg {
        position: fixed;
        top: 87.1% !important;
        right: 10% !important;
    }

    .cart-g {
        bottom: 45px;
    }

    /* .arp,
    .cart-g .bag-g {
        padding-bottom: 13px;
    } */

    .sm-web-footer ul>li:last-child button {
        padding: 0;
    }

    .sm-web-footer h2 {
        margin-top: 20px;
    }

    .ant-carousel .success-slider .carousel h4 {
        font-size: 14px;
        text-align: left;
    }

    .ant-carousel .success-slider .carousel {
        padding: 10px;
        text-align: left;
    }

    /* .web-slider h2 {
        width: 100%;
        word-wrap: break-word;
        line-height: 30px;
        font-size: 15px;
    }

    .web-slider h2 span {
        font-family: "BickleyScriptRegular";
        font-size: 40px;
    } */

    /* .web-slider .banner-img {
        margin-top: 30px;
    } */

    /* .web-slider .heart-img {
        width: 200px;
        margin-top: -40px;
        margin-left: 50px;
    } */

    .ul-horizontal {
        text-align: center;
    }

    .sm-page .about h1 {
        text-align: center;
    }

    .sm-page .about span {
        font-family: 'BickleyScriptRegular';
        font-size: 4rem;
        font-weight: 400;
    }

    .about-paragraph p {
        text-align: justify;
        font-size: 15px;
        font-weight: 450;
    }

    .south-about {
        padding-left: 40px;
        padding-right: 40px;
    }

    .show-start .sm-btn-black {
        width: 100% !important;
        /* margin-top: 10px !important; */
    }

    .rowgap {
        row-gap: 0px !important;
    }

    /* .hover-upload-card .auto-grid .box .content-hover {
        height: 100vh;
    } */

    .not-found {
        position: relative;
        left: 30%;
        width: 100px !important;
    }

    .cart .not-found {
        left: 25% !important;
    }

    .table .not-found {
        left: 50%;
        width: 130px;
        position: absolute;
        transform: translate(-50%, 0%);
        top: 8rem !important;
    }

    .table .no-data {
        position: absolute;
        width: 150px;
        position: absolute;
        font-size: 16px;
        top: 27rem !important;
        transform: translate(-50%, 0%);
        margin-left: 10%;
        white-space: nowrap;
    }

    .success-slider .no-data {
        position: relative;
        left: -10px !important;
        top: -10px !important;
        font-size: 14px !important;
    }

    .branch-item-not-found {
        position: relative;
        left: 30%;
    }

    .no-data {
        position: relative;
        left: 30% !important;
        top: 30px !important;
    }

    .contact-page .ant-card {
        padding: 0px !important;
    }

    .con-page {
        row-gap: 15px !important;
    }


    .con-page .spc {
        text-align: center;
        white-space: nowrap !important;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: stretch;
        justify-content: center;
        align-items: center;
    }

    .contact-page span {
        /* text-align: left; */
        margin-left: 10px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    /* .contact-page h1>span {
        margin-left: -25px !important;
    } */

    .contact-page .icon-img {
        width: 2.3rem !important;
    }

    .sm-page .success-stories h1 {
        font-weight: 550;
        font-size: 30px;
        white-space: nowrap;
    }

    .rounds {
        width: 100% !important;
        height: 100% !important;
        margin-left: 20px !important;
        overflow: hidden;
        display: flex;
        justify-content: left !important;
        flex-wrap: wrap;
        align-content: left !important;
        flex-direction: row;
    }

    #profile_filter Button {
        width: 100%;
    }

    .mob-btn Button {
        width: 100% !important;
    }

    .ant-drawer {
        display: block;
    }

    .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
        width: 100% !important;
        /* border-radius: 15px 0 0 !important; */
        overflow: hidden !important;
    }

    .drawer-header {
        display: flex;
        justify-content: space-between;
    }

    .app-head .ant-menu.ant-menu-dark {
        text-align: center !important;
    }

    .ant-menu.ant-menu-dark {
        margin-top: 10px;
        text-align: right !important;
    }

    .ant-drawer-header-close-only {
        display: none;
    }

    .ant-drawer-content-wrapper .drawer-small-logo {
        /* margin-left: 1rem; */
        width: 30px;
    }



    /* 
    .ant-menu-submenu-open .ant-menu-submenu-active {
        margin-left: 3rem !important;
    } */


    /* .ant-menu-dark .ant-menu-sub,
    .ant-menu.ant-menu-dark .ant-menu-sub:nth-child(2) {
        margin-left: 3rem !important;
    } */

    .ant-drawer-content-wrapper .cross-icon {
        margin-top: 0.8rem;
        width: 13px;
    }

    .ant-drawer-content-wrapper .footer-icon {
        position: absolute;
        left: 2rem;
        bottom: 1rem;
        width: 12rem;
    }

    .drawer-icon {
        position: absolute;
        left: 0.4rem;
        top: -2px;
    }

    .search-drawer-icon {
        position: absolute;
        top: 0.5rem;
        right: 0.1rem;
        padding: 8px;
        background-color: #fff;
        border-radius: 5px;
        margin-right: 10px !important;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    .upload-profile .search-title {
        padding-top: 10px;
        font-size: 1.4rem;
    }

    .drawer-web-icon {
        position: absolute;
        left: 0.4rem;
        top: 2rem;
    }
}

@media only screen and (max-width: 748px) {

    /* ol {
        padding: 5px;
        margin: 0;
        margin-bottom: 1em;
    } */

    .sm-page .about .para-txt {
        margin-left: 1.1rem;
        padding-top: 2px;
    }

    .sm-page .about .sub-num {
        margin-left: -2rem !important;
    }

    .sm-page .about .sub-text-ol {
        margin: 0;
        padding: 0;
    }

    .sm-page .about .sub-text-ol .para-subtxt {
        margin-left: 2rem !important;
        padding-top: 2px !important;
    }

    .mob-terms {
        height: auto;
    }

    .sm-page .about .sub-text-ol .para-num {
        margin-left: -1.8rem !important;
    }

    .sm-page .about .sub-text-order-ol {
        margin: 0;
        padding: 0;
    }

    .sm-page .about .sub-text-order-ol .para-numorder {
        margin-left: -1.2rem;
    }

    .sm-page .about .sub-text-order-ol .para-subtxtorder {
        margin-left: 3.2rem;
        padding-top: 2px;
    }

    .sm-page .about .sub-num-one {
        margin-left: -5rem !important;
    }

    .sm-page .about .sub-text {
        margin-left: 0.3rem !important;
    }

    .sm-page .about .para-num {
        vertical-align: text-top;
    }

    .sm-page .about .para-subtxt {
        margin-left: 2rem;
    }

    .meet-groom p {
        margin-top: -5px;
        line-height: 1.3rem !important;
        font-size: 13px;
        font-weight: 400;
        white-space: pre-wrap;
        word-wrap: break-word;
        text-overflow: ellipsis !important;
    }

    .mob-notify-body {
        display: block;
        background: #fff;
        margin: 30px 20px;
        padding: 10px 2px 10px 15px;
        width: 90%;
        height: 70%;
        overflow-y: auto;
    }
}


/* Home page  End*/



/* Profile view home page */

@media only screen and (max-width: 600px) {

    .profile-view .background {
        text-align: center;
        /* padding-bottom: 0px; */
    }


    .card-imgss {
        width: 80%;
    }

    .shortlist-btn Button {
        margin-top: 12px !important;
    }

    .view-btn Button {
        margin-top: 12px !important;
    }

    .profile-step {
        height: auto !important;
        text-align: center;
        padding: 20px 40px;
        margin: 20px 30px 0px !important;
    }

    .profile-step .sm-slider .ant-steps {
        margin-left: -1rem !important;
        padding: 0;
    }



    .profile-step .sm-slider .ant-steps-item-description {
        padding-top: 3px !important;
    }



}


/* Profile view home page */



@media only screen and (max-width: 600px) {
    .sm-app-header {
        padding: 0 10px;
    }

    .dashboard-count {
        padding: 20px !important;
    }

    .sm-app .add-page {
        padding: 35px 25px !important;
        background-color: #F8F8FB;
    }

    .member-profile-form {
        margin-left: -5px;
        margin-right: 1.2rem;
    }

    .app-page .logo-header {
        margin-top: 10px;
        margin-left: -22px;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        background-size: cover;
    }

    .app-page .anticon svg {
        position: absolute;
        top: 2em;
        left: 0;
    }

    .dashboard-count .no-data {
        position: relative;
        left: 30% !important;
    }
}

/* Chithra */

/* Profile-uploadedProfiles, paidprofiles Start */

@media only screen and (max-width: 600px) {

    .poly-arrow {
        margin-top: 0px;
    }

    .color-ball-filter {
        overflow-x: hidden !important;
        padding: 40px 20px;
    }
}

/* Profile-uploadedProfiles, paidprofiles End */

/* Search start */

@media only screen and (max-width: 600px) {

    .red-head img {
        margin-left: 10px;
    }
}

/* Search End */

/* Report start */

@media only screen and (max-width: 600px) {

    .report-page .report-table .sm-btn {
        margin-top: 15px;
        padding: 0.5em 1em;
    }

    .report-page .report-table h1 {
        text-align: center;
    }

    .report-page .table tbody h1 {
        margin-top: -130px;
    }

    .ant-card-body {
        padding: 15px;
    }

    .add_padd {
        padding: 50px 20px;
    }

    .paid-profiles .active {
        margin-left: 1.8em;
    }

    .add_padd .paid-link {
        margin-left: 1.8em;
    }

    .table .report-location {
        width: 200px;
        max-width: 150px;
        word-wrap: break-word;
        text-align: left !important;
    }

}

/* Report End */

/* Add new profile-primaryinfo start */

/* @media only screen and (max-width: 600px) {
    .add-page .new {
        width: 200px !important;
    }
} */

@media only screen and (max-width: 720px) {
    .profile-view {
        padding: 4rem 1.4rem;
    }


    /* .profile-view {
        padding: 50px 30px;
    } */

}


/* Add new profile-primaryinfo End */


/* Add new profile-partnerpreference start */

@media only screen and (max-width: 724px) {

    .hover-upload-card .auto-grid .box .content p,
    .matching-profile .head-highlight-no {
        text-align: center !important;
    }

    .hover-upload-card .auto-grid .box .card-imgs {
        width: 60px;
        height: 55px;
        border-radius: 4px;
    }


    .success-story-popup .img-size {
        margin-left: 2rem !important;
    }

    .success-story-popup .title-size {
        margin-left: 2rem !important;
    }

    .success-story-popup .about-size {
        margin-left: 1rem !important;
    }
}

/* Add new profile-partnerpreference End */



/* Edit profile start */

@media only screen and (max-width: 600px) {

    .save_btn {
        padding-left: 10px !important;
        /* margin-left: -150px !important; */
        margin-right: 40px !important;
    }

    .items p {
        display: flex;
        position: relative;
    }
}

/* Edit profile End */

/* Single view profile start */

@media only screen and (max-width: 600px) {

    .app-page {
        padding: 10px 30px 20px !important;
    }

    /* .app-page {
        padding: 30px !important;
    } */

    .hover-upload-card {
        padding: 0 10px;
    }

    .matching-profile .hover-upload-card {
        padding: 0 !important;

    }

    .upload-profile {
        padding: 20px 0px;
    }

    .upload-cards {
        padding: 0 1rem 0 1rem !important;
        /* padding: 0 14px 0 5px !important; */
        /* padding: 0 18px 0 5px !important; */
    }

    .upload-cards .hover-upload-card {
        padding-left: 0.4rem !important;
    }

    .profile-view .profile-details {
        padding: 20px 5px 20px 15px;
    }

    .profile-view .profile-details h5 {
        display: flex;
        position: relative;
        font-size: 13px;
    }

    .profile-view .profile-details .common {
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }

    .profile-view .profile-details h6 {
        margin-left: 3px;
        font-size: 12.5px;
        margin-top: 2px;
        word-wrap: break-word;
        text-align: right;
    }

    .matching-profile {
        padding: 40px !important;
    }

    .matching-profile .not-found {
        width: 150px;
        position: relative;
        top: 30%;
        left: 50%;
        margin: 3rem 0;
    }

    .profile-view .card-img-avaa {
        width: 80%;
        margin: 10px 0;
    }

    .matching-profile .no-data {
        position: absolute;
        top: 65% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }


    .profile-view .background .card-icon img {
        margin-bottom: 20px;
    }

    .switch-button .icon {
        text-align: center;
        margin-top: 10px;
    }

    .icon {
        display: flex;
        gap: 10px;
    }

    .profile-view .background .green-blue {
        margin-top: 0px !important;
    }

    .profile-view .background .icon .printer {
        margin-left: 0px !important;
    }

    .ant-tabs-tab-active {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .tab-card .ant-tabs {
        display: flex !important;
        flex-direction: column !important;
    }
}

/* Single view profile end */

/* Chithra */

/* Chithra */

/* read full story start*/

@media only screen and (max-width: 1024px) {

    img.img-size {
        margin-left: 10px !important;
    }

    h4.title-size {
        margin-left: 10px;
    }

    p.about-size {
        margin-left: 10px;
    }
}

/* read full story end*/

/* Chithra */

/* Chithra */

/* cart start */

@media only screen and (max-width: 1024px) {

    .right-alignment {
        margin-left: 270px !important;
    }

    .size-txt {
        right: 65px;
        margin-top: 30px;
    }

    h3.discount {
        margin-left: -60px;
        margin-top: 30px;
    }


}

/* cart end */

/* Chithra */