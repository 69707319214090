:root {
	--u: 3px;

}


.loader {
	width: calc(var(--u) * 40);
	height: calc(var(--u) * 40);
	position: relative;
	animation: spin 8s linear 0s infinite;
}

.ball {
	position: absolute;
	width: 100%;
	height: 100%;
	margin-top: calc(var(--u) * -12);
	margin-left: calc(var(--u) * -12);
	border-radius: 100%;
	transform: rotate(5deg);
}

.ball:nth-child(2) {
	margin-top: calc(var(--u) * 2);
	transform: rotate(-90deg);
}

.ball:nth-child(3) {
	margin-top: calc(var(--u) * 12);
	margin-left: calc(var(--u) * 12);
	transform: rotate(135deg);
}

.ball:nth-child(4) {
	margin-left: calc(var(--u) * 12);
	transform: rotate(0deg);
}

.table-loader {
	width: 85vw;
	overflow: hidden;
}


.rounds {
	width: 100vh;
	height: 50vh;
	margin-left: 280px;
	overflow: hidden;
	display: flex;
	justify-content: center !important;
	flex-wrap: wrap;
	align-content: center !important;
	flex-direction: row;
}

.ball:before {
	--ball: #ffc107;
	--ball2: #ff9800;
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;

	animation: spin 2s linear 0s infinite;
	background:
		radial-gradient(circle at 92% 50%, var(--ball) calc(var(--u) * 3), #0000 calc(var(--u) * 3.25)),
		radial-gradient(circle at 92% 49%, var(--ball) calc(var(--u) * 2.5), #0000 calc(var(--u) * 3.125)),
		radial-gradient(circle at 8% 50%, var(--ball2) calc(var(--u) * 3), #0000 calc(var(--u) * 3.25)),
		radial-gradient(circle at 8% 51%, var(--ball2) calc(var(--u) * 2.5), #0000 calc(var(--u) * 3.125));
}

.ball:nth-child(2):before {
	--ball: #448aff;
	--ball2: #1565c0;
}

.ball:nth-child(3):before {
	--ball: #f44336;
	--ball2: #ad1457;
}

.ball:nth-child(4):before {
	--ball: #8bc34a;
	--ball2: #009688;
}

.mirror {
	transform: rotate(180deg);
	position: absolute;
	width: 100%;
	height: 100%;
	filter: hue-rotate(180deg);
}

@keyframes spin {
	0% {
		transform: rotate(0deg)
	}

	100% {
		transform: rotate(360deg)
	}
}