html,
body {
    margin: 0;
    padding: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Poppins', sans-serif;*/
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    color: #121212;
    font-size: 14px;
}

.ant-scrolling-effect {
    width: 100% !important;
}

body {
    overflow-x: hidden !important;
}

@font-face {
    font-family: 'BickleyScriptRegular';
    src: local('BickleyScriptRegular'), url(./assets/font/BickleyScriptRegular.ttf) format('truetype');
}

ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

ul.ul-horizontal>li {
    display: inline-block;
}

a {
    color: #121212;
    text-decoration: none;
}

iframe {
    pointer-events: auto;
}

.txt-right {
    text-align: right !important;
}

.register-member-info {
    text-align: right !important;
}

.txt-left {
    text-align: left !important;
}

.txt-center {
    text-align: center !important;
}

.form-header {
    text-align: center;
    padding: 20px 0px 5px;
}

.form-header>h4 {
    font-size: 22px;
    text-transform: upp;
    font-weight: 520;
}

.sm-btn {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #eee;
    background-color: #eee;
    color: #000;
    transition: all 0.3s ease-in-out;
    font-weight: 400;
}

.sm-btn.sm-btn-danger {
    background-color: #D91214;
    border: 1px solid #D91214;
    color: #FFFFFF;
}

.sm-btn.sm-btn-danger:hover {
    background-color: #000000;
    border: 1px solid #000000;
    color: #FFFFFF;
}

.sm-btn.sm-btn-black {
    background-color: #000000;
    border: 1px solid #000000;
    color: #FFFFFF;
}

.sm-btn.sm-btn-black:hover {
    background-color: #D91214;
    border: 1px solid #D91214;
    color: #FFFFFF;
}

.sm-btn.sm-btn-border {
    background-color: #ffffff;
    border: 1px solid #ffffff;
}

.sm-btn.sm-btn-border.sm-blue {
    background-color: #ffffff;
    border: 1px solid #3C66C4;
    color: #3C66C4;
}

.sm-btn.sm-btn-border.sm-blue:hover {
    background-color: #3C66C4;
    border: 1px solid #3C66C4;
    color: #ffffff;
}

.sm-btn.sm-btn-border.sm-red {
    background-color: #ffffff;
    border: 1px solid #CF4332;
    color: #CF4332;
}

.sm-btn.sm-btn-border.sm-red:hover {
    background-color: #CF4332;
    border: 1px solid #CF4332;
    color: #ffffff;
}

.white {
    background-color: #fff !important;
    color: #CF4332 !important;
    font-weight: 500;
}

.ant-divider-horizontal.ant-divider-with-text {
    border-top-color: #AAAAAA;
}

.ant-divider {
    padding-left: 40px;
    padding-right: 40px;
}

#root,
.sm-auth,
.sm-web,
.sm-app {
    height: 100vh;
}

.fh {
    height: 100%;
}

.sm-layout {
    min-height: 100%;
    height: auto;
    width: 100%;
    display: block;
}

.sm-web-header {
    background-color: #eee;
}

.sm-web {
    height: 100vh !important;
    align-items: center;
    background: url(./assets/backgrounds/colour-ball.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.sm-web-footer {
    background-color: #121212;
}

.login-footer h1 {
    font-size: 13px;
    color: #fff;
    font-weight: 300;
}

/* .sm-page>section {
    padding: 40px 0px;
} */

.sm-web-header .logo,
.sm-app-header .logo {
    float: left;
    /* width: 12rem; */
    /* width: 120px;
    height: 31px; 
   background: rgba(255, 255, 255, 0.3);*/
    margin: 16px 24px 16px 0;
}

.sm-app-header .logo-img {
    width: 70% !important;
    margin-left: 30px;
}



.page-login,
.page-register {
    height: calc(100% - 120px);
}

.page-login,
.page-register {
    padding: 0px 80px;
}

.auth-bg {
    background: url(./assets/backgrounds/auth_bg.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    opacity: 0.9;
}

.auth-header {
    padding: 30px 80px 0px 80px;
}

.auth-header .logo {
    height: 36px;
}

.auth-header p {
    color: #ffffff;
    font-weight: 400;
    margin-top: 5px !important;
}

.auth-header .agent-para {
    margin-left: -20px;
}

.auth-header .sm-btn {
    font-size: 13px;
    white-space: nowrap;
}


.sm-card {
    background: url(./assets/backgrounds/flower.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 20px;
    border-radius: 7px;
    margin: 0px auto;
}

.sm-auth .ant-form {
    padding: 0px 40px;
}

.sm-auth .member-form {
    padding: 0 22px !important;
}

.sm-auth .member-form .ant-form-item {
    margin-bottom: 15px;
}

.sm-auth .member-form .ant-form-item-explain-error {
    margin-bottom: 0 !important;
}

.sm-auth .left-icon:nth-child(1) {
    width: 12px;
    margin-right: 8px;
}

.sm-auth .left-icon:nth-child(2) {
    width: 12px;
    margin-left: 8px;
}

.sm-auth .prev-btn {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
    font-size: 13px;
    font-weight: 450;
    border-radius: 5px;
    padding: 0.4rem 0;
}

.sm-auth .prev-btn:hover,
.sm-auth .next-btn:hover {
    background-color: #000;
    color: #fff;
    border: 1px solid #000;
}

.sm-auth .next-btn {
    background-color: #D91214;
    color: #fff;
    border: 1px solid #D91214;
    font-size: 13px;
    font-weight: 450;
    border-radius: 5px;
    padding: 0.4rem 0;
}

.sm-auth .ant-form .ant-input,
.sm-auth .ant-form .ant-select {
    height: 36px;
    border: 1px solid #121212;
    border-radius: 4px;
    box-shadow: none;
}

.new-select .ant-form .ant-select {
    border: none !important;
}

.new-select .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 36px;
    border: 1px solid #121212;
    border-radius: 4px;
    box-shadow: none;
}

.sm-auth .ant-form .ant-input:focus {
    border: 1px solid #121212;
    box-shadow: none;
}

.sm-auth .ant-form .ant-form-item-label {
    padding: 0px 0px 5px 0px;
}

.sm-auth .ant-form .ant-form-item-label>label {
    color: #121212;
}

.sm-auth .btn-groups {
    padding: 15px 40px;
}

.sm-auth .btn-groups .ant-btn {
    margin-bottom: 15px;
}

.ant-btn {
    height: auto;
}

.sm-slider h4 {
    font-size: 35px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    line-height: 50px;
}

.sm-slider h4>span {
    font-size: 65px;
    color: #fff;
    font-weight: 300;
    font-family: 'BickleyScriptRegular';
    ;
}

.sm-slider p {
    font-size: 14px;
    padding-right: 45px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
}

.sm-steps {
    margin-top: 40px;
}

.sm-steps .ant-steps-icon-dot {
    width: 36px !important;
    height: 36px !important;
    top: -14px;
}

.sm-steps .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #40D859;
}

.sm-steps .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot,
.sm-steps .ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: #ffffff;
}

.sm-steps .ant-steps-dot .ant-steps-item-tail::after,
.sm-steps .ant-steps-dot.ant-steps-small .sm-steps .ant-steps-item-tail::after {
    width: calc(100% - 50px) !important;
    height: 3px !important;
    margin-left: 40px !important;
}

.sm-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title,
.sm-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title,
.sm-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: #ffffff;
    font-size: 13px;
}

.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
    width: 170px !important;
    padding-top: 20px;
}

.sm-steps .ant-steps-label-vertical .ant-steps-item-content {
    margin-top: 15px !important;
}

.ant-steps-item-content {
    white-space: nowrap !important;
}

.sm-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #40D859;
}

.sm-app-header {
    padding: 13px 30px;
    background-color: rgba(255, 255, 255, 0.6);
    /* position: sticky !important;
    top: 0;
    height: auto;
    width: 100%;
    z-index: 999; */
}

/* .sm-web {
    padding-top: 90px;
}

.sm-app {
    padding-top: 90px;
} */


.sm-app-header h1 {
    margin-left: 5rem;
    margin-top: -3rem;
    white-space: nowrap;
    overflow: scroll;
    font-size: 1.4rem;
}

.ant-layout-header {
    display: flex;
    background-color: transparent;
}

.sm-main-menu {
    width: 100%;
}

.sm-main-menu>li span {
    font-size: 14px;
    font-weight: 500;
}


.ant-drawer-right>.ant-drawer-content-wrapper {
    border-radius: 15px 0 0 0;
}

.sm-main-menu>li span:hover {
    font-size: 14px;
    color: #000 !important;
    font-weight: 500;
}

.sm-main-menu .ant-menu-item:hover {
    color: red !important;
    background-color: transparent !important;
}

.red a {
    color: red;
}

.red a:hover {
    text-decoration: underline !important;
    color: red;
}

.otp-head span span {
    display: none;
}

.otp-head span {
    font-size: 14px !important;
    color: red;
    cursor: pointer !important;
}

.otp-head button {
    background-color: transparent !important;
    cursor: pointer !important;
}



/* Over ride */


.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
    color: #121212;
    background-color: #fff;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark,
.ant-menu.ant-menu-dark:hover {
    background-color: transparent;
}


.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item>a,
.ant-menu-dark .ant-menu-item>span>a {
    color: #121212;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item>a,
.ant-menu-dark .ant-menu-item>span>a:hover {
    color: red;
}

.ant-layout-header .sm-main-menu {
    flex-wrap: wrap;
    align-content: center;
    flex-direction: column-reverse;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: transparent;
}

.ant-menu-dark .ant-menu-item-selected>a,
.ant-menu-dark .ant-menu-item-selected>span>a,
.ant-menu-dark .ant-menu-item-selected>a:hover,
.ant-menu-dark .ant-menu-item-selected>span>a:hover {
    color: red;
    border-bottom: 2.5px solid red;
}

.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
    color: red !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
}

.ant-menu-horizontal>.ant-menu-item a:hover {
    color: red !important;
}

/* .ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 25px !important;
} */

/* Over ride */






/*profile view Active Pop Up Start*/


.activate .ant-modal-header,
.ant-modal-footer {
    display: none !important;
}


.activate .ant-select-selector {
    border: 1px solid #000 !important;
}

.activate textarea {
    border: 1px solid #000 !important;
}

.activate label {
    font-size: 14px;
    color: #000;
    font-weight: 500;
    padding-bottom: 10px;
}

.activate .ant-modal-body {
    padding: 40px;
}

.activate h2 {
    font-weight: 600;
    margin-bottom: 20px;
}

/*profile view Active Pop Up End*/








/* steps tracker  start */

.sm-slider .ant-steps {
    margin-top: 30px !important;
}

.sm-slider .ant-steps-item-description {
    color: #fff !important;
}

.sm-slider .ant-steps-icon {
    font-weight: 600;
}

.sm-slider .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    color: #fff !important;
}

.sm-slider .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #fff;
}

.sm-slider .ant-steps-item-tail::after {
    height: 3px;
}

.sm-slider .ant-steps-item-active .ant-steps-item-container .ant-steps-item-tail::after {
    background-color: #40D859 !important;
    border-color: #40D859 !important;
}

.sm-slider .ant-steps-item-active .ant-steps-item-icon {
    background-color: #40D859 !important;
    border-color: #40D859 !important;
}

.sm-slider .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #40D859 !important;
    border-color: #40D859 !important;
}

.sm-slider .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-tail::after {
    background-color: #40D859 !important;
    border-color: #40D859 !important;
}

.sm-slider .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #40D859 !important;
}

/* steps tracker end */

/* matches Search icon Start */

.search-icon {
    color: #AAAAAA !important;
    font-size: 18px;
}

.search-icon :hover {
    color: black !important;
}

/* skip or later/ previous start */

a {
    color: red;
    text-decoration: none;
}

a:hover {
    color: red;
}

.previous-btn {
    background-color: white;
    border: 1px solid black;
    color: black;
    border-radius: 5px;
    bottom: 43px !important;
    padding-bottom: 7px;
}

.previous-btn:hover {
    background-color: black;
    border: 1px solid black;
}

/* skip or later/ previous End */