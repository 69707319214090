@media print {

    body {
        overflow-x: hidden;
        margin: 0;
        padding: 0;
    }

    .ant-card-body {
        padding: 0px 24px !important;
        word-wrap: break-word;
    }

    .ant-card-bordered {
        border: none !important;
    }

    @page :left {
        margin-left: 0px;
    }

    @page :right {
        margin-left: 0px;
    }

    .profile-view {
        padding: 30px 5px 30px;
    }

    .flower-background {
        display: block;
    }

    .profile-basic {
        display: none;
    }

    .profile-family {
        padding: 20px 30px !important;
    }

    .profile-about {
        padding: 10px 30px !important;

    }

    .profile-basic .ant-col-xs-24 {
        display: block;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .print-name .ant-col-xs-24 {
        display: inline-block;
        flex: 0 0 80%;
        max-width: 80%;
    }

    table {
        width: 100%;
    }

    table,
    th,
    td {
        border: none;
        border-collapse: collapse;
    }

    td,
    th {
        text-align: left;
    }

    th {
        border: none;
    }

    .flower-background {
        margin-top: 10px;
        background-image: url("assets/backgrounds/flower.png");
        background-position: 20px 20px;
        background-size: cover;
        width: 100%;
    }

    .object-img {
        width: 110px;
        height: 120px;
        float: left;
        border-radius: 5px;
    }

    .flower-background tbody tr td p {
        line-height: 10px;
        padding-left: 130px;
    }

    .flower-background tbody tr .name-object {
        padding-top: 20px;
        font-weight: 550;
        font-size: 16px;
    }

    .flower-background tbody tr .identification-number {
        color: grey;
    }

    .flower-background tbody tr td {
        padding: 20px;
        color: #000;
    }

    .profile-view .profile-details {
        margin: -20px 0 !important;
        padding: 20px 0px;
    }

    .profile-view .profile-details .ant-col-xs-2 {
        text-align: right !important;
        display: inline-block;
        flex: 0 0 5%;
        max-width: 5%;
    }

    .profile-view .profile-details .ant-col-xs-10 {
        display: inline-block;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .profile-view .profile-details .ant-col-xs-11 {
        display: inline-block;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .partner-details .ant-col-xs-24 {
        display: inline-block;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .preview-family .ant-col-xs-2 {
        display: block;
        flex: 0 0 5%;
        max-width: 5%;
    }

    .preview-family .ant-col-xs-22 {
        display: block;
        flex: 0 0 95%;
        max-width: 95%;
    }

    .profile-details {
        padding: 0 !important;
    }

    .lifestyle {
        page-break-after: always;
    }

    .astro-detail-print {
        page-break-inside: avoid;
    }

    .sm-app {
        margin-top: 20px;
    }

    .sm-app .amsam {
        margin: -10px 0;
    }

    .amsam .ant-col-xs-12 {
        display: block;
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%;
    }

    .social-profile {
        margin-bottom: 20px;
        page-break-inside: avoid;
    }

    .green-box {
        display: block;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .footer-preview {
        display: block;
        position: fixed;
        width: 100%;
        bottom: 0;
        text-align: center;
    }

  
}

.footer-preview p {
    font-size: 14px;
    font-weight: 450;
}

/* .profile-basic .print-name .ant-col-xs-24 {
        display: block;
        flex: 0 0 80%;
        max-width: 80%;
    } */

/* .ant-col-xs-24 {
        display: block;
        flex: 0 0 75%;
        max-width: 75%;
    } */