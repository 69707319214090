/* Web Header Start */

.sm-app-header .ul-horizontal li {
  margin-top: 15px;
  margin-right: 15px;
}

.sm-app-header .ul-horizontal .sm-btn-login {
  background-color: #fff;
  border: 1px solid #D91214;
  color: #D91214;
  padding: 0.6em 3.5em;
  font-size: 12px;
}

.sm-app-header .ul-horizontal .sm-btn-register {
  background-color: #D91214;
  border: 1px solid #D91214;
  color: #FFFFFF;
  padding: 0.6em 3em;
  font-size: 12px;
}

.sm-app-header .logo-header {
  width: 4.3rem;
  height: 4.3rem;
  border-radius: 50%;
  background-size: cover;
}

.sm-btn-grey {
  background-color: #fff !important;
  border: 1px solid #000 !important;
  color: #000 !important;
  padding: 0.6em 3em;
  font-size: 12px;
  margin-top: 45px !important;
}


/* .ant-modal-mask {
  opacity: 0.8;
} */

.ant-modal-content {
  box-shadow: none !important;
}

.sm-page .arg {
  background-color: red;
  border-radius: 100%;
  height: 50px;
  cursor: pointer;
  width: 50px;
  border: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  position: fixed;
  z-index: 999 !important;
  right: 80px;
  top: 85%;
}

.role-page .arg {
  position: fixed;
  top: 86.5% !important;
  right: 15px !important;
}

.sm-page .arg .anticon {
  color: #fff;
  margin-left: 8px;
}


/* Web Header End */

/* Carousel Authentication Start */

::-webkit-input-placeholder {
  font-size: 12px;
}

input[type="text"] {
  font-size: 13px;
}

.proceed>.txt-center>.sm-btn {
  padding: 0.35em 5em;
  font-size: 12px;
  margin-top: 5px;
}

.btn>.txt-right>.sm-btn {
  padding: 0.38em 2em;
  font-size: 12px;
  margin-top: 5px;
}

.form-header>span {
  font-weight: 500;
}

.form-header>h4>span {
  font-size: 15px;
  color: #AAAAAA;
  font-weight: 450;
}

.form-header .animation-img {
  width: 70%;
  margin-top: -60px;
}


.form-header h4 {
  text-align: center;
  word-wrap: break-word;
  /* line-height: 15px; */
}

.profile-pic .ant-upload.ant-upload-drag {
  background: none !important;
  border: none !important;
}

.profile-pic .ant-upload-span {
  display: none !important;
}

.upload-img {
  width: 10em;
  margin: 0 auto;
  background-color: #DCEBFC;
  padding: 45px;
  border-radius: 50%;
  border: 1px solid #3A16B4;
}

.upload-img .avatar-icon {
  width: 50px;
}

.avatar-photo {
  background-color: #2680EB;
  width: 40px;
  position: absolute;
  height: 40px;
  right: 5px;
  border-radius: 50%;
  color: #fff !important;
}

.avatar-photos {
  width: 20px;
  margin-top: 10px !important;
  filter: invert(96%) sepia(1%) saturate(772%) hue-rotate(219deg) brightness(105%) contrast(100%);
}

.big-size {
  width: 140px;
  height: 140px;
  border-radius: 50%;
}

.upload-agent-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.ant-upload.ant-upload-select-picture-card {
  width: 140px !important;
}

.tool .ant-tooltip {
  display: none;
}

.tool .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye {
  display: none;
}

.tool .ant-upload-list-picture-card .ant-upload-list-item-info::before {
  /* background-color: grey !important; */
  margin-left: -42px;
}

.tool .ant-upload.ant-upload-select-picture-card {
  border: none;
}


.tool button {
  background-color: #2680EB;
  color: #fff;
  border: none;
  padding: 5px 25px;
  font-size: 12px;
  margin-top: 20px;
  border-radius: 4px;
}

.tool {
  margin-top: 40px;
}

.tool .ant-upload-list-item-info {
  display: none !important;
}

.tool .sm-btn-grey {
  padding: 0.3rem 2rem !important;
  /* padding: 10px 30px; */
}

.tool .sm-btn-danger {
  padding: 0.3rem 2.4rem !important;
  /* padding: 10px 30px !important; */
}

.sm-card .mobile-num-field .ant-input {
  border: none !important;
  box-shadow: none !important;
}

.sm-card .mobile-num-field .ant-input-affix-wrapper {
  padding: 0 0.4rem !important;
  margin: 0 !important;
  border: 1px solid #000;
  border-radius: 5px;
}

.sm-card .mobile-num-field .ant-input-prefix {
  font-size: 13px;
  margin-top: 0.4px;
}

.sm-card .mobile-num-field .ant-input-affix-wrapper {
  font-size: 13px;
}

.sm-auth .ant-form .ant-form-item-label>label {
  font-size: 14px;
  font-weight: 500;
}

.txt-right>.send-code {
  color: red;
  background-color: transparent !important;
  font-weight: 500;
  border: none !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

.txt-right>.send-code:hover {
  color: red !important;
  border: none !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

.ant-carousel .slick-dots-bottom {
  bottom: -10px;
}

.ant-carousel .slick-dots li.slick-active button {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  left: 0px;
  position: relative;
  background: #fff;
  opacity: 1;
}

.caste-dropdown .ant-checkbox-wrapper:first-child {
  margin-left: 9px;
}

.ant-carousel .slick-dots li button {
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.ant-carousel .slick-dots li.slick-active {
  width: 16px;
}

.ant-carousel .slick-dots {
  justify-content: left;
  margin-right: 0%;
  margin-left: 0%;
}

.form-footer p {
  color: #121212;
  font-size: 13.4px;
  font-weight: 430;
  /* color: #AAAAAA; */
}

.form-footer Button {
  font-size: 13px;
}

.form-footer .ant-divider {
  border-top: 2px solid #AAAAAA;
}

.ul-horizontal li a {
  padding: 0.60em 1em;
  font-size: 12px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  font-size: 12px;
  padding-top: 2px;
}

.news {
  border: 1px solid #121212 !important;
  height: 35px;
  border-radius: 3px;
}

.news .ant-input {
  border: none !important;
  height: 25px !important;
}

.ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.ant-form-item-explain-error {
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 14px;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}

/* Carousel Authentication End */


/* Footer Start */

.sm-web-footer {
  padding: 50px;
  min-height: 300px;
  padding-top: 200px;
}

.sm-web-footer button {
  margin: 0;
  padding: 0;
  color: #fff;
  background: transparent;
  border: none;
  cursor: pointer;
}

.sm-web-footer .foot-logo {
  width: 250px;
}

.sub-sm-web-footer {
  padding-top: 50px;
}

.social-images {
  width: 100%;
  white-space: nowrap;
}

.social-images .facebook {
  width: 20px;
  margin-right: 10px;
  margin-bottom: 50px;
}

.arp {
  width: 15px;
}

.social-images .twitter,
.youtube,
.instagram {
  width: 25px;
  margin-right: 10px;
  margin-bottom: 50px;
}

.sm-web-footer h2 {
  color: #fff;
  font-size: 25px;
}

.sm-web-footer h5 {
  color: #fff;
  font-size: 17px;
  margin-top: 50px;
}

.sm-web-footer ul {
  color: grey;
  line-height: 30px;
  margin-top: 15px;
}

.sm-web-footer ul li a {
  color: #f6f6f6;
  line-height: 30px;
  font-weight: 300 !important;
  font-size: 14px;
}

.mini-footer {
  background-color: #121212;
}

.mini-footer h5 {
  text-align: center;
  color: #ffff;
  font-weight: 300 !important;
  font-size: 13px;
  padding: 10px;
  padding-bottom: 5px;
}

.our-branches .ant-row iframe {
  width: 25rem;
  height: 25rem;
  border-radius: 50%;
  border: 0;
}

.join-card {
  height: 170px;
  padding: 0px 220px;
}

.join-card-mob {
  /* height: 13rem; */
  background: url(./assets/backgrounds/join.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border-radius: 5px;
  padding: 1rem 2rem 0.2rem;
  margin: -30px 0 30px;
  width: 100%;
}

.join-card .join-free {
  background: url(./assets/backgrounds/join.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border-radius: 20px;
  padding: 60px;
  width: 100%;
  position: relative;
}

.join-card .sm-btn {
  margin-top: 20px;
  padding: 10px;
  width: 160px;
  font-size: 13px;
  margin-right: 10px;
}

.join-free h1 {
  color: #fff;
}

.join-card-mob h3 {
  font-size: 1rem;
  padding: 0 2rem;
  color: #fff;
}

.join-card-mob h5 {
  font-size: 0.8rem;
  color: #fff;
}

.join-free h1 span {
  font-size: 60px;
  color: #fff;
  font-family: 'BickleyScriptRegular';
  line-height: 70px;
}

.join-card-mob h3 span {
  font-size: 30px;
  color: #fff;
  font-family: 'BickleyScriptRegular';
  line-height: 30px;
}

.join-card-mob .sm-btn {
  padding: 0.25rem 0.35rem;
  width: 100%;
  font-size: 0.64rem;
}

.join-free h4 {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .join-card {
    height: auto;
    padding: 0px;
  }

  .join-card .join-free {
    padding: 30px;
    border-radius: 20px 20px 0px 0px;
  }
}


/* Footer End */



/*Page Filter Start */

.page-registers .dashboard-card {
  padding: 80px;
  background-color: #F8F8FB;
}

.sm-app {
  background: url(./assets/backgrounds/colour-ball.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 600px;
}

.color-ball-filter {
  padding: 50px 80px;
  /* min-height: 900px; */
}

.show-start .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #121212;
  padding-top: 5px !important;
}

.show-start .sm-btn-black {
  padding: 0.85em 3em;
  margin-top: 35px;
  font-size: 13px;
}

.show-start .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #121212;
  padding-top: 5px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  font-weight: 500;
  padding-top: 1px;
  font-size: 13px;
  color: #000;
}

.show-start .ant-select {
  position: relative;
  display: block;
  cursor: pointer;
  margin-top: 5px;
}

.show-start .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  padding-top: 10px;
}

.show-start label {
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow-x: auto;
}

.show-start .search-img {
  width: 20px;
  padding-right: 6px;
  filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(108deg) brightness(104%) contrast(104%);
}


/*Page Filter End */




/*Card Header Add Start */

.upload-profile {
  padding: 80px 0px 30px;
}

.upload-profile h2 {
  font-weight: 600;
  font-size: 25px;
}

.upload-profile .add-new {
  background-color: #D91214;
  font-size: 11px;
  border: none;
  color: #fff;
  width: 100%;
  padding: 0.79em 0em;
}

.upload-profile .add-new img {
  width: 10px;
  margin-right: 10px;
}

.upload-profile .filter-new {
  background-color: #000;
  font-size: 15px;
  border: none;
}

.upload-profile .filter-new img {
  width: 10px;
}

.upload-profile .ant-input {
  border: 2px solid rgb(215, 214, 214);
  border-radius: 5px;
  height: 35px;
}

.main-search {
  border: 2px solid rgb(215, 214, 214);
  border-radius: 5px;
  height: 35px;
}

.whatsapp-icon {
  width: 30px;
  height: 30px;
}

.phone-num-error {
  position: absolute;
  top: 2.7rem;
  font-weight: 420;
  left: 1rem;
  font-size: 12px;
  color: #D91214;
}

.phone-num .ant-input-prefix {
  font-size: 13px;
  padding: 0.64rem 0;
}

.color-ball-filter .ant-input {
  height: 22px;
}

.ant-input-affix-wrapper>.ant-input:not(textarea) {
  padding: 0;
  border: none;
}

.mobile-no-data-found {
  display: none;
}

/*Card header Add End */




/* All Hover Background Card Start */


.auto-grid {
  --auto-grid-min-size: 16rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  grid-gap: 1.5rem;
}

.hover-upload-card .auto-grid .ant-card-body {
  padding: 15px;
  padding-bottom: 5px;
}

.hover-upload-card .auto-grid .ant-card {
  border-radius: 7px;
  box-shadow: 0px 0px 2px #00000029;
}


.hover-upload-card .auto-grid .box .content h3 {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  text-align: center !important;
  white-space: nowrap;
  line-height: 20px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.hover-upload-card .auto-grid .box .content p {
  font-size: 12px;
  font-weight: 500;
  color: #AAAAAA;
  margin-top: -7px;
}


.hover-upload-card .auto-grid .box {
  position: relative;
  display: flex;
  flex-direction: column;
}

.hover-upload-card .auto-grid .box .card-img {
  width: 100%;
  height: 200px;
  border-radius: 7px;
}

.box .lock-img {
  position: absolute;
  z-index: 1;
  top: 2%;
  right: 1%;
  width: 30px;
  height: 30px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 5px;
  border-radius: 50%;
  background-color: #fff;
}

.hover-upload-card .auto-grid .box .card-img-agent {
  width: 100%;
  height: 180px;
  border-radius: 7px;
}


.card-imgss {
  width: 80%;
}

.book-mark {
  width: 15px;
}


.hover-upload-card .auto-grid .box .card-imgg {
  width: auto;
  height: 180px;
  border-radius: 50%;
}

.hover-upload-card .auto-grid .box .card-img-ava {
  height: 13rem;
  border-radius: 50%;
}

.hover-upload-card .auto-grid .public-card-img {
  margin-bottom: 1.3rem;
}


.primary-info-img {
  width: 10rem;
  height: 10rem;
  border-radius: 4px;
  margin-top: 25px;
  cursor: pointer;
}

.del-imgs {
  position: absolute;
  top: 1.7em;
  cursor: pointer;
  right: -5px;
  width: 4rem;
  padding: 4px;
  border-radius: 50%;
}

.submitBlue-btn {
  color: white;
  background-color: #2680EB;
  border-radius: 5px;
  margin-top: 15px;
  font-size: 12px;
}

.submitBlue-btn:hover {
  color: white;
  background-color: black;
  border-color: black;
}

.profile-top-dummy {
  width: 3rem;
  height: 3rem;
  margin-top: -12px;
  border-radius: 5px;
  margin-right: 7px;
}

.profile-top-img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 5px;
  margin-right: 7px;
  /* margin-top: -10px; */
}

.profile-top .space-margin {
  margin-top: -13px;
}

.profile-top .add-space {
  margin-top: 2.5px;
}

.profile-top .add-spacee {
  margin-top: 3.2px;
}

.profile-top span {
  display: inline-block;
  white-space: nowrap;
  padding: 13px 5px 0px;
  overflow: hidden;
  text-overflow: ellipsis !important;
  width: 50px !important;
}

.profile-tops,
.profile-to {
  padding: 0 5em 0 5px;
}

.profile-tops img {
  width: 35px;
  height: 35px;
  margin-right: 6px;
  border-radius: 50px;
}

.profile-tops .icon-img {
  background-color: #2680EB29;
  padding: 10px;
}

.profile-to img {
  width: 35px;
  height: 35px;
  margin-right: 6px;
  border-radius: 50px;
}

.profile-to .icon-ig {
  background-color: #D9121429;
  padding: 10px;
}

.ant-popover-inner-content {
  z-index: 99;
  position: relative;
}

.hover-upload-card .auto-grid .box .content-hover {
  transform: scale(0, 0);
  position: absolute;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  margin: -10px;
  padding: 10px;
  padding-top: 15px;
  cursor: pointer;
}

.share-icon {
  position: absolute;
  top: 7px;
  z-index: 100;
  right: 1.4em;
  width: 25px;
  height: 25px;
  background-color: #15C97B;
  padding: 6px;
  box-shadow: 0px 0px 2px #00000029;
  border-radius: 4px;
  cursor: pointer;
}

.more-icon {
  position: absolute;
  top: 10px;
  z-index: 100;
  right: 0;
  font-size: 16px;
  font-weight: 600;
  color: #000 !important;
  cursor: pointer;
}

.shortlist-icon {
  position: absolute;
  top: 10px;
  z-index: 100;
  right: 7%;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.shortlist-icon-paid {
  position: absolute;
  top: 10px;
  z-index: 100;
  right: 19%;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.hover-upload-card .auto-grid .box:hover>.content-hover {
  transform: scale(1, 1);
}

.hover-upload-card .auto-grid .box .card-imgs {
  width: 50px;
  height: 50px;
  border-radius: 4px;
}

/* Notification Part Start */

.notify-body {
  margin: 10px 10px;
  width: 25rem;
  height: 70%;
  overflow-y: auto;
}

.notify-count {
  position: absolute;
  background-color: #fff;
  top: 1rem;
  left: 4.2rem;
  font-size: 12px;
  font-weight: 600;
  padding: 3px 9px;
  box-shadow: 0 0 2px rgb(0 0 0 / 10%);
  border-radius: 50%;
}

.notify-div {
  display: inline-block;
}

.blur-img {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.meet-groom .lock-img {
  position: absolute;
  z-index: 1;
  top: 12%;
  right: 20%;
  width: 30px;
  height: 30px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 5px;
  border-radius: 50%;
  background-color: #fff;
}

.view-profile .lock-img {
  position: absolute;
  z-index: 1;
  top: 12%;
  right: 20%;
  width: 30px;
  height: 30px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 5px;
  border-radius: 50%;
  background-color: #fff;
}

.notify-body .empty-data {
  display: block;
  margin: 30px auto;
  text-align: center;
  font-size: 17px;
}

.bell-icon {
  margin-right: 10px;
  width: 16px;
  cursor: pointer;
  filter: invert(23%) sepia(70%) saturate(3964%) hue-rotate(346deg) brightness(80%) contrast(116%);
}

.notify-item p {
  font-size: 12px;
  color: #AAAAAA;
}

.notify-item .accept-btn {
  border: none;
  color: #0D8A11;
  text-align: left;
  padding: 0;
}

.notify-item .accept-btn span {
  text-decoration: underline;
  text-decoration-color: #0D8A11;
  font-size: 12px;
  font-weight: 450;
}

.notify-img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.notify-head {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.notify-head .ant-btn,
.notify-item .reject-btn {
  border: none;
  color: #D91214;
}

.notify-body .notify-item {
  margin: 10px 0;
  cursor: pointer;
}

.notify-head .ant-btn span,
.notify-item .reject-btn span {
  padding: 0;
  text-decoration: underline;
  text-decoration-color: #D91214;
  font-size: 12px;
  font-weight: 450;
}

/* Notification Part End */


.hover-upload-card .auto-grid .box .content-hover h1 {
  font-size: 13px;
  white-space: nowrap;
  font-weight: 600;
  overflow: hidden;
  width: 120px !important;
  text-align: left;
  line-height: 15px;
  text-overflow: ellipsis !important;
  margin-left: 5px;
  margin-top: 2px;
}

.hover-upload-card .auto-grid .box .content-hover .e-head {
  font-size: 13px;
  white-space: nowrap;
  font-weight: 500;
  overflow: hidden;
  width: 12rem !important;
  text-align: left;
  line-height: 15px;
  text-overflow: ellipsis !important;
  margin-left: 5px;
  margin-top: 2px;
}

.hover-upload-card .auto-grid .box .content-hover .card-icon {
  width: 16px;
  margin-top: -5px;
}

.hover-upload-card .auto-grid .box .content-hover h5 {
  font-size: 9px;
  margin-top: -4px;
  margin-left: 5px;
  color: #AAAAAA;
}

.hover-upload-card .auto-grid .box .content-hover h6 {
  font-size: 9px;
  margin-left: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.hover-upload-card .auto-grid .box .content-hover h4 {
  font-size: 12px;
  margin-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 200px;
}

.hover-upload-card .auto-grid .box .content-hover .sm-btn {
  font-size: 12px;
  background-color: #2680EB;
  border-radius: 4px;
  color: #fff;
}

.hover-upload-card .auto-grid .box .content-hover .sm-btn:hover {
  background-color: #000;
  color: #fff;
  border: 1px solid #000;
}

.hover-upload-card .auto-grid .box .content .orange {
  background-color: #F5880D;
  color: #fff;
  position: absolute;
  top: -7px;
  right: -7px;
  padding: 2px 15px;
  z-index: 1;
  font-size: 11px;
  border-radius: 20px;
  text-transform: uppercase;
}

.hover-upload-card .auto-grid .box:hover .orange,
.hover-upload-card .auto-grid .box:hover .match-point-btn,
.hover-upload-card .auto-grid .box:hover .lagna-yes-btn,
.hover-upload-card .auto-grid .box:hover .lagna-no-btn,
.hover-upload-card .auto-grid .box:hover .lagna-medium-btn {
  display: none;
}

.hover-upload-card .auto-grid .box .content .green {
  background-color: #0D8A11;
  color: #fff;
  position: absolute;
  top: -7px;
  right: -7px;
  padding: 2px 15px;
  z-index: 1;
  font-size: 11px;
  border-radius: 20px;
  text-transform: uppercase;
}

.hover-upload-card .auto-grid .box:hover .green {
  display: none;
}

.hover-upload-card .auto-grid .box .content .blue {
  background-color: #2680EB;
  color: #fff;
  position: absolute;
  top: -13px;
  left: -13px;
  padding: 10px;
  z-index: 1;
  font-size: 9px;
  border-radius: 100%;
  text-transform: uppercase;
}

.hover-upload-card .auto-grid .box .content:hover .blue {
  display: none;
}

.online {
  width: 6px;
  margin-right: 3px;
}

/* All Hover Background Card End */



/* Profile View Start */

.profile-view {
  padding: 40px 180px;
}

.big-panel .ant-collapse-content>.ant-collapse-content-box {
  padding: 16px;
  height: 12rem;
  overflow-y: auto;
}

.profile-view .images-count {
  position: absolute;
  bottom: 5px;
  right: 5px;
  padding: 5px 5px 5px 5px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  width: 30px;
  border-radius: 3px;
  height: 30px;
}

.profile-view .ant-card {
  border-radius: 5px;
}


.profile-view .ant-card-body {
  padding: 0px;
  border-radius: 10px;
}

.profile-view .ant-image-img {
  width: 250px;
  height: 220px;
  border-radius: 10px 0px 0px 0px;
}

.profile-view .ant-image-mask {
  width: 100%;
  height: 220px;
  border-radius: 10px 0px 0px 0px;
}

.profile-view .background {
  background: url(./assets/backgrounds/flower.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border-radius: 0px 10px 0px 0px;
  padding: 20px;
}


.profile-view .background h1 {
  font-size: 19px;
  font-weight: 600;
  word-break: break-all;
}

.profile-view .background .card-icon img {
  width: 28px;
  height: 28px;
  background-color: #fff;
  margin-left: 10px;
  padding: 7px;
  box-shadow: 0px 0px 2px #00000029;
  border-radius: 4px;
  cursor: pointer;
}

.ant-switch-checked {
  background-color: #0D8A11;
}

.profile-view .background h5 {
  font-size: 13px;
  color: #AAAAAA;
  margin-top: -2px;
}

.profile-view .background h6 {
  font-size: 13px;
}

.profile-view .background .yellow {
  border-color: #FCA302;
  color: #FCA302;
  background-color: #FFF0D6;
  margin-top: 10px;
  font-size: 13px;
}

.profile-view .background .yellow:hover {
  color: #fff;
  background-color: #FCA302;
}

.profile-view .background .green {
  border-color: #0D8A11;
  color: #0D8A11;
  background-color: #D8ECD8;
  margin-top: 10px;
  font-size: 13px;
}

.profile-view .background .green:hover {
  color: #fff;
  background-color: #0D8A11;
}

.profile-view .background .blue {
  border-color: #2680EB;
  color: #fff;
  background-color: #2680EB;
  margin-top: 10px;
  font-size: 13px;
}

.profile-view .background .blue:hover {
  color: #fff;
  background-color: #000;
  border: 1px solid #000;

}

.profile-view .background .ink-blue {
  border-color: #3A16B4;
  color: #fff;
  background-color: #3A16B4;
  margin-top: 10px;
  font-size: 13px;
}

.profile-view .background .ink-blue:hover {
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
}

.profile-view .background .green-blue {
  border-color: #0D8A11;
  color: #fff;
  background-color: #0D8A11;
  margin-top: 10px;
  font-size: 13px;
}

.profile-view .background .cart-btn {
  margin-top: 3rem;
}

.profile-view .background .green-blue:hover {
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
}

.profile-view .background .card-icon .eye-icon {
  width: 28px;
  height: 28px;
  background-color: #15C97B !important;
  padding: 6px;
  box-shadow: 0px 0px 2px #00000029;
  border-radius: 4px;
  cursor: pointer;
}

.profile-view .background .icon .printer {
  width: 33px;
  height: 33px;
  background-color: #000;
  padding: 9px;
  box-shadow: 0px 0px 2px #00000029;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
}

.profile-step {
  position: relative;
  margin: 30px 60px 0px !important;
  top: -5px;
  padding: 2rem;
  height: auto;
  background-color: #DCEBFC;
  border-radius: 5px;
  border: 1px solid #2680EB;
}

.profile-step .sm-slider .ant-steps-item-description {
  color: #121212 !important;
  white-space: nowrap;
}

.profile-step h4 {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.profile-step a {
  color: #2680EB;
  text-decoration: underline;
  font-size: 15px;
}

.profile-step p {
  color: #D91214;
  cursor: pointer;
  text-decoration: underline;
  font-size: 15px;
}

.partner-logo {
  display: none;
}


.profile-step .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
  background: #fff;
  border: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.profile-step .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon>span {
  color: #121212;
}


.profile-step .sm-slider .ant-steps {
  margin: 0 !important;
}

.profile-view .sm-slider h4 {
  color: #121212 !important;
}


.profile-view .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border: none;
}

.profile-view .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
  color: #121212 !important;
}

.profile-view .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon>span {
  color: #121212 !important;
}

.profile-step .sm-slider .ant-steps-item-container {
  cursor: pointer;
}

.cartt-img {
  width: 12px;
  margin-right: 10px;
}

.profile-view .profile-details {
  padding: 20px;
}

.profile-view .profile-details .common {
  width: 17px;
  height: 17px;
}

.profile-view .profile-details h5 {
  font-size: 13.5px;
  margin-top: 2px;
  color: #AAAAAA;
  margin-left: 5px;
}

.profile-view .profile-details h6 {
  font-size: 13.5px;
  margin-top: 2px;
  word-wrap: break-word;
}

.profile-view .part-card {
  margin-top: 30px;
}

.profile-view .part-card h1 {
  padding: 100px;
}

/* Profile View End */





/* Report Page Start */

.report-page {
  /* background-color: #fff; */
  background-color: #F8F8FB;
  padding-bottom: 3em;
}

.add_padd {
  padding: 50px 80px;
  padding-bottom: 30px;
  background-color: #F8F8FB;
}

.pagi {
  background-color: #F8F8FB;
  padding-bottom: 20px;
}

.pagi .anticon {
  color: #fff;
}



.report-page .report-table,
.scbscripe-page .report-table {
  box-shadow: 0px 0px 2px #00000029;
  border-radius: 6px;
  background-color: #fff;
  min-height: 500px;
}

.report-page .report-table .sm-btn {
  padding: 0.4em 2em;
  font-size: 12px;
  margin-top: -5px;
}

.report-page .report-table .card-img {
  padding: 9px;
  width: 30px;
  margin-right: 5px;
  background-color: #AAAAAA33;
  border-radius: 4px;
  margin-top: 0px !important;
}

.report-page a {
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  padding-bottom: 3px;
  white-space: nowrap;
}

.report-page .report-table .active {
  color: red;
  border-bottom: 2.5px solid red;
}

.report-page .report-table h1 {
  font-size: 16px;
}

.report-page .upload-table {
  overflow-x: auto;
}

.report-modal h3 {
  font-size: 1.3rem;
  font-weight: 500;
}

.report-modal p {
  font-size: 13px;
  color: #AAAAAA;
  font-weight: 450;
}

.report-modal h5 {
  font-size: 1rem;
  font-weight: 500;
}

.report-modal .remark {
  font-size: 1.15rem;
  font-weight: 450;
  color: #AAAAAA;
  word-wrap: break-word;
}

.table .report-name {
  width: 20%;
  max-width: 120px;
  word-wrap: break-word;
}

.table .report-location {
  width: 20%;
  max-width: 140px;
  word-wrap: break-word;
  text-align: left !important;
}


.report-page .table {
  width: 100%;
  margin-top: 10px;
}


.report-page .table thead {
  background-color: #AAAAAA33;
}

table {
  border-collapse: collapse;
  border-radius: 7px;
  overflow: hidden;
  margin-top: 5px;
}


.report-page .table thead tr th {
  font-size: 13px;
  font-weight: 500;
  padding: 9px;
  white-space: pre;
}

.report-page .table tbody tr td {
  font-size: 12px;
  font-weight: 400;
  padding: 9px;
  margin-top: 20px;
  /* text-align: center; */
}

.report-page .table tbody tr td .green {
  color: #0D8A11;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
}

.report-page .table tbody tr td .red {
  color: red;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
}

/* .report-page .table tbody img {
  width: 10%;
  position: absolute;
  left: 45%;
  top: 150px;
} */

.report-page .table tbody h1 {
  width: 20%;
  position: absolute;
  left: 40%;
  top: 300px;
}

.not-found {
  width: 150px;
  position: relative;
  left: 560px;
  top: 60px;
}

.success-slider {
  position: relative !important;
}

.success-slider .not-found {
  position: absolute;
  width: 100px !important;
  height: 100px !important;
  position: relative !important;
  left: 50% !important;
  transform: translate(-50%, 0%);
}

.success-slider .no-data {
  font-size: 14px !important;
  padding-bottom: 20px;
  position: relative;
  top: 10%;
  left: 44%;
}


.matching-profile .not-found {
  width: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.matching-profile .no-data {
  position: absolute;
  font-size: 16px;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.table .not-found {
  width: 150px;
  position: absolute;
  transform: translate(-50%, 0%);
  left: 50%;
  top: 8rem !important;
}

.table .no-data {
  position: absolute;
  font-size: 16px;
  top: 13rem !important;
  transform: translate(-50%, 0%);
  margin-left: 10%;
}

.no-data {
  position: relative;
  left: 560px;
  margin-top: 90px;
}

/* Report Page End */






/* About Page Start */

.sm-page .about {
  /* font-family: "Avenir LT Pro"; */
  height: auto !important;
  align-items: center;
  text-align: justify;
}

.sm-page .about .about-img {
  width: 55%;
  justify-content: center;
}

.sm-page .about h1 {
  font-weight: 600;
  font-size: 55px;
}

.sm-page .about span {
  font-family: 'BickleyScriptRegular';
  font-size: 65px;
  font-weight: 400;
}

.sm-page .about p {
  font-size: 1.1rem;
  font-weight: 430;
}

.sm-page .about-paragraph {
  text-align: justify;
  padding: 0px 80px;
  background-color: #fff;
  font-size: 1.1rem;
  font-weight: 430;
}


.sm-page .about .para-head {
  font-size: 1.1rem;
  font-weight: 430;
}

.sm-page .about .para-title {
  font-size: 1.5rem;
  font-weight: 430;
  text-decoration: underline;
}

.sm-page .about .para-ans {
  font-size: 1.1rem;
  font-weight: 410;
  margin-left: 1.2rem;
  color: #AAAAAA;
}

.sm-page .about .para-txt {
  font-size: 1.1rem;
  font-weight: 430;
  margin-left: 2.9rem;
  margin-top: -35px !important;
}

.sm-page .about .para-subtxt {
  font-size: 1.1rem;
  font-weight: 430;
  margin-left: 4rem;
  margin-top: -35px !important;
}

.sm-page .about .para-subtxtorder {
  font-size: 1.1rem;
  font-weight: 430;
  margin-left: 6.5rem;
  margin-top: -35px !important;
}

.sm-page .about .para-num {
  font-size: 1.2rem;
  font-weight: 500;
  text-align: left;
}

.sm-page .about .para-numorder {
  font-size: 1.2rem;
  font-weight: 500;
  margin-left: 1.4rem;
}

.sm-page .success-stories .stories {
  background-color: #F3F4F8;
  padding: 100px 80px;
  align-items: center;
}

.sm-page .success-stories h1 {
  font-weight: 550;
  font-size: 40px;
}

.sm-page .success-stories h1 span {
  font-weight: 520;
  font-size: 65px;
  font-family: 'BickleyScriptRegular';
}


.sm-page .success-stories p {
  font-size: 15px;
}


.ant-carousel .ant-card-body {
  padding: 0px;
}

.success-slider .slick-list {
  border-radius: 10px;
  box-shadow: 0px 6px 30px #00000029;
}

.ant-carousel .success-slider .slick-dots {
  position: absolute;
  left: 45%;
}

.ant-carousel .success-slider .slick-dots li button {
  background: #7b7b7b80;
  margin-top: 20px !important;
  width: 10px;
  height: 10px;
}

.ant-carousel .success-slider .slick-dots .slick-active button {
  background-color: #D91214;
}

.ant-carousel .success-slider img {
  width: 100%;
  height: 250px;
  border-radius: 10px 0px 0px 10px;
  border: none;
}

.ant-carousel .success-slider .dummy-img {
  width: 95%;
  margin: 0.6rem;
  height: 14.4rem;
  border-radius: 50%;
  border: none;
}

.ant-carousel .success-slider .carousel {
  padding: 30px;
}

.ant-carousel .success-slider .carousel h4 {
  font-size: 18px;
  font-weight: 550;
}

.ant-carousel .success-slider .carousel p {
  font-size: 13.5px;
  color: #000;
}

.ant-carousel .success-slider .carousel .sm-btn {
  font-size: 13px;
  background-color: transparent;
  border-radius: 5px;
  color: #D91214;
  border: 1px solid#D91214;
  font-style: normal;
  padding: 0.6em 2em;
  font-weight: 600;
  margin-top: 20px;
}

.ant-carousel .success-slider .carousel .sm-btn:hover {
  font-size: 13px;
  background-color: #D91214;
  border-radius: 5px;
  color: #fff;
  border: 1px solid#D91214;
  font-style: normal;
  padding: 0.6em 2em;
  font-weight: 600;
  margin-top: 20px;
}

/* About Page End */



/* Our Branches Start*/

.our-branches {
  padding: 50px 80px;
  background-color: #fff;
  width: 100%;
}

.our-branches .ant-row {
  display: flex;
  flex-flow: row wrap !important;
  justify-content: space-around;
}

.our-branches h1 {
  font-size: 35px;
  font-weight: 600;
}

.our-branches h1 span {
  font-family: 'BickleyScriptRegular';
  font-size: 60px;
  font-weight: 520;
}

.our-branches img {
  width: 240px;
  height: 240px;
  border-radius: 7px;
  cursor: pointer;
}

.our-branches img:hover {
  border-radius: 7px;
  cursor: pointer;
}

.our-branches .ant-image-mask {
  border-radius: 7px;
  display: none;
}

.our-branches .ant-row {
  padding: 30px 0px;
}

.our-branches h2 {
  font-size: 17px;
  margin-top: 10px;
  font-weight: 600;
  /* margin-left: -30px !important; */
}

.our-branches p {
  font-size: 13px;
  font-weight: 400;
  /* margin-left: -30px !important; */
  line-height: 10px;
}

.our-branches h5 {
  font-size: 16px;
  margin-top: -25px;
}

/* Our Branches End */





/* Meet Grooms & Brides Start*/

.meet-groom {
  padding: 50px 80px;
  background-color: #F3F4F8;
  width: 100%;
}

.meet-groom .ant-row {
  display: flex;
  justify-content: space-around;
}

.meet-groom h4 {
  margin-top: 20px !important;
  font-size: 17px;
}

.meet-groom h1 {
  font-size: 35px;
  font-weight: 520;
}

.meet-groom h1 span {
  font-family: 'BickleyScriptRegular';
  font-size: 60px;
  font-weight: 520;
  margin-left: 5px;
}

.meet-groom h5 {
  font-size: 16px;
  margin-top: -25px;
}

.meet-groom img {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  cursor: pointer;
}

.meet-groom img:hover {
  cursor: pointer;
}

.meet-groom .ant-image-mask {
  border-radius: 7px;
  display: none;
}

.meet-groom .ant-row {
  padding: 50px 0px;
}

.meet-groom h2 {
  font-size: 17px;
  margin-top: 10px;
  font-weight: 600;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  width: 200px;
  line-height: 3rem !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.meet-groom p {
  line-height: 0.8rem !important;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* margin-left: -30px !important; */
}

.meet-groom .location {
  line-height: 1rem !important;
  width: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.meet-groom button {
  padding: 0.75em 2.5em;
}

/* Meet Grooms & Brides End */



/*  Home Page about Blog Start */

.about-blog .blog {
  padding: 60px 80px;
  display: flex;
  align-items: center;
}

.about-blog .blog p {
  text-align: justify;
  font-size: 16px;
  font-weight: 400;
}

.about-blog .blog img {
  width: 80%;
  padding-bottom: 20px;
}

.about-blog .blog h1 {
  font-size: 40px;
  font-weight: 600;
}

.about-blog .blog h1 span {
  font-size: 60px;
  font-weight: 400;
  font-family: 'BickleyScriptRegular';
  margin-left: 5px;
}

.about-blog .blog button {
  padding: 0.75em 2em;
  color: #fff;
  margin-top: 20px;
  font-size: 13px;
  margin-right: 15px;
}

/*  Home Page about Blog End */





/* Web Profile Page Start */


.web-profile {
  padding: 50px 80px;
}

.profiles .sm-btn {
  background-color: red !important;
}

.profiles .sm-btn:hover {
  border: none;
}

.web-profile .profiles {
  padding: 30px 0px;
}

.web-profile .profiles h2 {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
}

.web-profile .profiles h2 span {
  text-align: center;
  font-size: 65px;
  font-weight: 520;
  font-family: "BickleyScriptRegular";
}

.web-profile .profiles p {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: -30px;
}

/* Web Profile Page End */


/* Ant Pagination Start */


.ant-pagination-item {
  border-radius: 50%;
}

.ant-pagination-item-link {
  border-radius: 50% !important;
  background-color: #000 !important;
}

.ant-pagination-item-active {
  border: none;
}

.ant-pagination-item-active a {
  color: #fff;
  background-color: #D91214;
  border-radius: 50% !important;
}

/* .anticon {
  color: #fff;
} */

.ant-pagination-item {
  background-color: #F9D8D9;
  border-radius: 50% !important;
}

/* Ant Pagination End */



/* Web Slider Home Page Start */

.web-slider {
  padding: 100px 80px;
  display: flex;
  align-items: center;
}

.web-slider .ant-row {
  display: flex;
  align-items: center;
}

.web-slider .banner-img {
  width: 90%;
}

.web-slider:hover .btns button {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.web-slider .heart-img {
  width: 200px;
  margin-top: -140px;
  margin-left: -50px;
}

.web-slider h2 {
  font-size: 37px;
  line-height: 55px;
}

.web-slider h2 span {
  font-family: "BickleyScriptRegular";
  font-size: 80px;
}

.web-slider .sm-btn {
  padding: 0.75em 2em;
  color: #fff;
  font-size: 13px;
  margin-top: 10px;
  margin-right: 15px;
}

.web-slider .btns button {
  font-size: 1rem;
  font-weight: 450;
  padding: 10px 20px;
  border: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.web-slider .secure-btn {
  position: absolute;
  top: 50%;
  left: 10%;
}

.web-slider .support-btn {
  position: absolute;
  top: 40%;
  right: 5%;
}

.web-slider .profiles-btn {
  position: absolute;
  top: 70%;
  left: 20%;
}

.web-slider .alerts-btn {
  position: absolute;
  top: 60%;
  right: 5%;
}

.web-slider .secure-btn img,
.web-slider .support-btn img,
.web-slider .profiles-btn img,
.web-slider .alerts-btn img {
  width: 1.7rem;
  margin-right: 10px;
}

/*  Web Slider Home Page End */


/* Web Home Page Filter Start */

.home-filter {
  padding: 50px 80px;
  background-color: #ffff;
  margin-top: -100px;
}

.home-filter h3 {
  font-size: 20px;
  font-weight: 510;
  color: #000;
  margin-bottom: 20px;
}

.home-filter button {
  padding: 0.75em 2em;
  font-size: 13px;
  background-color: #000;
  color: #fff;
  border: none;
}

.home-filter .search-img {
  width: 14px;
  margin-right: 6px;
  filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(108deg) brightness(104%) contrast(104%);
}

.arg-img {
  position: absolute;
  top: -30px;
  left: 480px;
  width: 100px;
}

/* Web Home Page Filter End */


/* Web Search Page Start */

.sm-search {
  padding: 50px 80px;
}


.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  position: relative;
  display: flex;
  font-size: 13px;
  padding: 12px 0px 12px 16px;
  color: #fff;
  /* line-height: 1.5715; */
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
  align-content: stretch;
  flex-direction: row-reverse;
  justify-content: space-between;
  background-color: #000;
  border: 2px solid #fff;
  border: none !important;
}

.poly-arrow,
.poly {
  width: 17px;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  color: #fff;
  font-size: 15px;
  margin-top: 2px;
}

.sm-search .ant-collapse {
  border: none;
}

/* Web Search Page end */

/* Safety Tips Start */

.sm-page .about ul {
  margin-left: 3rem;
}

.sm-page .about ul li {
  list-style-type: disc;
}

.terms {
  height: 80%;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
}

/* .terms ul li {
  list-style-type: none !important;
} */


.sm-page .about li.para-ans {
  margin-bottom: 10px;
  margin-left: -0.4rem !important;
}



/* Safety Tips ENd */


/* Profile View pages Button color Start */
.page-login Button {
  font-size: 12px;
}

.page-login Button i {
  padding-left: 5px;
}

.page-login .sm-red {
  padding: 0.55em;
}

.page-login .sm-blue {
  padding: 0.55em;
}


.icon-dropdown {
  width: 25px;
  height: 20px;
  margin-right: 10px;
}

.drop-data .ant-space-item {
  margin-top: 20px;
  font-size: 13px;
}

.drop-data .poly-arrow {
  padding-top: 2px;
}

.bags {
  margin-right: 20px;
}

.form-head {
  padding: 20px;
}

.photos-head {
  margin: -10px 0 -20px;
}

.form-head a {
  color: red;
  /* position: relative; */
  top: 6px;
  text-decoration: underline;
}

.form-head h5 {
  font-size: 21px;
  color: #000;
  font-weight: 600;
}

.scroll button {
  background-color: red;
  border-radius: 100%;
  height: 50px;
  cursor: pointer;
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.scroll button .anticon {
  color: #fff;
  margin-left: 8px;
}

.ant-drawer {
  display: none;
}

.cart-g {
  background-color: #2680EB !important;
  border-radius: 100%;
  width: 50px !important;
  height: 50px !important;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  position: fixed;
  bottom: 50px;
  right: 100px;
  border: none;
}

.cart-g a .anticon {
  color: #fff;
  margin-top: -240px !important;
  margin-left: 8px;
}

.cart-g .bag-g {
  filter: invert(100%) sepia(8%) saturate(7443%) hue-rotate(263deg) brightness(110%) contrast(114%);
}

.profile-dropdown {
  border-radius: 4px;
  width: 35px;
}

.ant-dropdown-menu {
  margin-top: 10px;
}

.ant-dropdown-menu-title-content {
  padding-top: 5px;
  font-size: 12px;
}

.color-ball-filter .anticon {
  color: #fff;
}

.pagination-area .anticon {
  color: #fff;
}

.pagination-area .ant-select-selector {
  height: 40px !important;
  padding-left: 10px !important;
}

/* Profile View pages Button color End */



/* popover body start  */

.pop-body-ant {
  height: 300px;
  overflow-y: scroll;
}

.pop-body-ant .ant-form-item .search {
  width: 12px;
}

.pop-body-ant .ant-form-item {
  margin-bottom: 0px;
}

.trigger .ant-form-item {
  margin-bottom: 0px;
}

.pop-body-ant label {
  line-height: 30px;
}

/* popover body start */



/* Pagination Area Start */

.pagination-area {
  margin-top: 50px;
}

/* Pagination Area End */


.ant-checkbox-group {
  display: flex;
  flex-direction: column;
  line-height: 30px;
}

/* .ant-checkbox {
  border-color: #121212 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #D91214;
} */




.steps-content {
  min-height: 200px;
  margin-top: 16px;
  padding-top: 80px;
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
}

.close_icon {
  position: absolute;
  top: -2rem;
  width: 1.5rem;
  right: 0;
}

.roles {
  display: flex;
  margin: 30px 0 0;
  gap: 0.8rem;
  padding: 10px 5px 0;
  border-radius: 5px;
  cursor: pointer;
}

.edit_agent p {
  font-size: 12px;
  margin-top: -5px;
}

.roles img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.roles div {
  flex: 1;
}

.roles div h5 {
  font-size: 14px;
  margin-top: 6px;
  font-weight: 530;
  word-wrap: break-word;
}

.roles div p {
  font-size: 12px;
  color: #AAAAAA;
  margin-top: -8px;
}

.roles:hover {
  background-color: #AAAAAA82;
}

.roles button {
  width: 4.3rem;
  padding: 3px;
  align-self: center;
  background-color: #D9121429;
  color: #D91214;
  border-radius: 4px;
  font-size: 10px;
  border: none;
  margin-right: 10px;
}

/* Saarch Start */

.cross {
  position: relative;
  right: 5%;
  cursor: pointer;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: .3;
  pointer-events: all;
}

input[type="search"].dark::-webkit-search-cancel-button {
  filter: invert(1);
}

/* Saarch End */

.water {
  position: absolute;
  width: 37px;
  left: 5px;
}

.water-lite {
  position: absolute;
  width: 10px;
  left: 5px;
}


.ant-pagination-options-size-changer.ant-select {
  display: none;
}

/*Member registration Start*/

.skip {
  text-align: right;
  text-decoration: underline;
}

.details-name {
  font-size: 16px;
}


/*member registration End*/

/* agent reg start */

.ant-btn>span {
  display: inline-block;
  /* text-decoration: underline; */
}


.ant-upload-list-picture-card .ant-upload-list-item {
  margin: 8px 8px 8px 0;
}

.cart-count {
  position: absolute;
  top: 0.9em;
  left: 0.8em;
  color: #000;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 3px 9px;
  box-shadow: 0 0 2px rgb(0 0 0 / 10%);
  border-radius: 50%;
}

.cart-counts {
  position: absolute;
  top: -12px;
  left: 27px;
  color: #000;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 3px 9px;
  box-shadow: 0 0 2px rgb(0 0 0 / 10%);
  border-radius: 50%;
}


.ant-select-arrow {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.3s ease-in, 0.3s ease-out;
}

.ant-select-open .ant-select-arrow {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: 0.3s ease-in, 0.3s ease-out;
  /* transform: rotate(90deg); */
}


/* .wrapper2

&.active
  ul
    margin-top: 12px
  .arrow
   



/* agent reg end */

/* read full story start*/

.success-story-popup .img-size {
  border-radius: 10px 10px 10px 10px;
  width: 175px !important;
  margin-left: 10rem !important;
}

.success-story-popup .title-size {
  font-size: 18px;
  font-weight: 550;
  margin-top: 20px;
  margin-left: 9rem !important;
}

.success-story-popup .about-size {
  font-size: 13px !important;
  color: #000 !important;
  margin-left: 6rem !important;
}

.mob-notify-body {
  display: none;
}

.match-point-btn {
  position: absolute;
  z-index: 99;
  width: 32px;
  height: 32px;
  padding: 5px;
  background-color: #2680EB;
  border: none;
  color: #fff;
  text-align: center;
  top: -2px;
  left: -7px;
  border-radius: 50%;
}

.match-point-span {
  margin-top: 1px;
  font-size: 8px;
}

/*  */

/* read full story end*/


/* scrollbar start*/

::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.sm-card .ant-picker {
  width: 100%;
  box-shadow: none;
  border: 1px solid #121212 !important;
  min-height: 34px;
  margin-top: 0px !important;
  border-radius: 4px;
}

/* Lagna Match Button Start */

.lagna-yes-btn {
  position: absolute;
  z-index: 99;
  width: 30px;
  height: 30px;
  padding: 5px;
  border: none;
  color: #fff;
  text-align: center;
  top: -2px;
  left: 2rem;
  border-radius: 50%;
  background-color: #0D8A11;
  font-size: 12px;
}

.lagna-no-btn {
  position: absolute;
  z-index: 99;
  width: 30px;
  height: 30px;
  padding: 5px;
  border: none;
  color: #fff;
  text-align: center;
  top: -2px;
  left: 2rem;
  border-radius: 50%;
  background-color: #D91214;
  font-size: 12px;
}


.lagna-medium-btn {
  position: absolute;
  z-index: 99;
  padding: 5px;
  border: none;
  color: #fff;
  text-align: center;
  top: -2px;
  left: 2rem;
  border-radius: 20px;
  background-color: #F5880D;
  font-size: 12px;
  width: 5rem;
}

/* Lagna Match Button End */




/* scrollbar End*/

/*member-lifestyle start*/

.save {
  padding-left: 6px;
}

/*member-lifestyle end*/

.app-links img {
  width: 150px;
  margin-top: 20px;
}